import React from 'react';
import { Box, Text, HStack, Image } from '@chakra-ui/react';

// Importing images
import instagramStoryIcon from '../../assets/instagramstory.png';
import instagramReelIcon from '../../assets/instagramreel.png';
import instagramPostIcon from '../../assets/instagrampost.png';
import tiktokVideoIcon from '../../assets/tiktok.png';

const iconMapping = {
  'Instagram Story': instagramStoryIcon,
  'Instagram Reel': instagramReelIcon,
  'Instagram Post': instagramPostIcon,
  'TikTok Video': tiktokVideoIcon,
};

function Deliverables({ offerData }) {
  if (!offerData) {
    return <Text>Loading...</Text>;
  }

  // Extract the deliverables from the offerData
  const { deliverables } = offerData;

  return (
    <Box textAlign="center" p={4}>
      <HStack spacing={4} overflowX="auto" p={4} justify="center">
        {deliverables.map((deliverable, index) => (
          <Box
            key={index}
            p={4}
            bg="gray.50"
            borderWidth="1px"
            borderRadius="full"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"

          >
            <HStack spacing={2}>
              <Image
                src={iconMapping[deliverable.name]}
                alt={deliverable.name}
                boxSize="30px"
              />
              <Text fontSize="18px" fontWeight="bold">{deliverable.name}</Text>
              <Text fontSize="18px" >x{deliverable.count}</Text>
            </HStack>
          </Box>
        ))}
      </HStack>
    </Box>
  );
}

export default Deliverables;
