import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

function NotFound() {
  return (
    <Box p={4} textAlign="center">
      <Heading as="h1" size="2xl">404</Heading>
      <Text fontSize="lg">Page Not Found</Text>
    </Box>
  );
}

export default NotFound;
