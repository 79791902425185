import React from "react";
import {
  Box,
  Heading,
  Image,
  Flex,
} from "@chakra-ui/react";
import icon from "../../assets/logo.png";

const Header = () => {

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="center"
        borderBottomWidth="1px"
        borderBottomColor="gray.300"
        py={6}
      >
        <Image src={icon} alt="hoo.be logo" boxSize="40px" mr={3} />
        <Heading size="lg" color="gray.800">
          hoo.be
        </Heading>
      </Flex>
    </Box>
  );
};

export default Header;