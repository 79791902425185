import React, { useEffect, useState } from 'react';
import { Text, Spinner, Box, Center } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Deliverables from './offer/Deliverables';
// import Data from './offer/Data';
import Intro from './offer/Intro';
import Header from './offer/Header';

function Offer() {
    const { workspaceId, campaignId, creatorId } = useParams();
    const [offerData, setOfferData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOfferData = async () => {
            try {
                const response = await axios.post('https://hoobe-server-v2.onrender.com/campaigns-offer-page', {
                    workspaceId,
                    campaignId,
                    creatorId,
                });
                setOfferData(response.data);
            } catch (err) {
                setError('Failed to fetch offer data.');
            } finally {
                setLoading(false);
            }
        };

        fetchOfferData();
    }, [workspaceId, campaignId, creatorId]);

    if (loading) {
        return (
            <Center height="100vh">
                <Spinner size="xl" />
            </Center>
        );
    }

    return (
        <Center>
            {error ? (
                <Text color="red.500">{error}</Text>
            ) : (
                <Box width="100%">
                    <Header offerData={offerData} />
                    <Intro offerData={offerData} />
                    <Deliverables offerData={offerData} />
                    {/* <Data offerData={offerData} /> */}
                </Box>
            )}
        </Center>
    );
}

export default Offer;
