import React from 'react';
import { Box, VStack, Heading, Text, Center, Image } from '@chakra-ui/react';

const Intro = ({ offerData }) => {
  if (!offerData) return null;

  // Extract relevant information from the offerData
  const {
    mongoData: {
      name,
      instagram_profile: { profile_pic_url, username },
    },
    subject,
    message,
  } = offerData;

  return (
    <Center>
      <Box bg="white" mt={10} p={8} borderRadius="xl" borderWidth="1px" textAlign="center">
        <VStack spacing={4}>
          <Image
            borderRadius="full"
            boxSize="150px"
            src={profile_pic_url}
            alt={`${name}'s profile picture`}
            mb={4}
          />
          <Heading as="h2" size="xl" color="gray.800">
            Thank you, {name}!
          </Heading>
          <Text fontSize="xl" fontWeight="semibold" color="gray.700">
            We're excited to present an offer tailored just for you.
          </Text>
          <Text fontSize="lg" color="gray.600" mt={4}>
            {subject}
          </Text>
          <Text fontSize="md" color="gray.600" mt={2}>
            {message}
          </Text>
          <Text fontSize="lg" fontWeight="bold" color="gray.800" mt={4}>
            Instagram Handle: @{username}
          </Text>
        </VStack>
      </Box>
    </Center>
  );
};

export default Intro;
